import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import grey from "@material-ui/core/colors/grey"
import teal from "@material-ui/core/colors/teal"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"

const useStyles = makeStyles({
  ContainerOne: {
    background: "#4e54c8",
    height: "max-content",
    color: grey[900]
    // marginTop: "55px"
  },
  imagehero: {
    zIndex: 1,
    backgroundImage: "SamuraiDot"
  },
  plants: {
    color: teal[200]
  },

  samuraidot: {
    position: "relative",
    height: "50%",
    zIndex: 0,
    top: 20
  },
  ContainerTwo: {
    padding: "1rem"
  },
  label: {
    textTransform: "capitalize"
  },
  gridContainer: {
    padding: "1rem"
  },
  cardCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
    minHeight: "min-content",
    margin: "1rem"
  }
})

const Index = () => {
  const classes = useStyles()
  return (
    <Layout>

      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} s={8} md={4}>
          <Typography align="center" variant="h2">
            <span>Developing new</span> <span>{"&"}</span>{" "}
            <span>creative</span>
            <span> smoking accessories for enjoying </span>{" "}
            <span className={classes.plants}>plants</span>
          </Typography>
        </Grid>
        <Grid item xs={12} s={8} md={4}>
          <StaticImage
            className={classes.imagehero}
            src="../images/SumoMainBlack.png"
            alt="Samurai Blaze Home Page"
            placeholder="blurred"
            layout="fullWidth"
            maxWidth={500}
            maxHeight={500}
          />
        </Grid>
      </Grid>

      <Container className={classes.ContainerTwo}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid Grid item xs={12} s={4} md={4}>
            <Card className={classes.cardCenter}>
              <CardContent>
                <CardMedia></CardMedia>
                <Typography variant="h4" align="center">
                  Our Products
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                  component="p"
                >
                  Super high quality smoking accessories, engineered and
                  manufactured by us.
                </Typography>
              </CardContent>
              <CardActions>
                <Link to="/shop">

                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                  >
                    Learn more
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid Grid item xs={12} s={4} md={4}>
            <Card className={classes.cardCenter}>
              <CardContent>
                <CardMedia></CardMedia>
                <Typography variant="h4" align="center">
                  Our Shop
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                  component="p"
                >
                  Shop all products and smoking accessories from the brands we
                  love
                </Typography>
              </CardContent>
              <CardActions>
                <Link to="/shop">

                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                  >
                    Shop Now
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid Grid item xs={12} s={4} md={4}>
            <Card className={classes.cardCenter}>
              <CardContent>
                <CardMedia></CardMedia>
                <Typography variant="h4" align="center">
                  Coming Soon
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                  component="p"
                >
                  New stuff we are working on
                </Typography>
              </CardContent>
              <CardActions>
                {/* <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick=""
                >
                  Learn More
                </Button> */}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container></Container>
    </Layout>
  )
}

export default Index
